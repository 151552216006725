exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-course-js": () => import("./../../../src/pages/course.js" /* webpackChunkName: "component---src-pages-course-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-joinus-js": () => import("./../../../src/pages/joinus.js" /* webpackChunkName: "component---src-pages-joinus-js" */),
  "component---src-pages-more-js": () => import("./../../../src/pages/more.js" /* webpackChunkName: "component---src-pages-more-js" */),
  "component---src-pages-newslist-js": () => import("./../../../src/pages/newslist.js" /* webpackChunkName: "component---src-pages-newslist-js" */)
}

